import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import {
  createProduct,
  DeleteSingleProduct,
  getallProducts,
} from "../api/ProductsApi";
import Table from "react-bootstrap/Table";
import { Toaster, toast } from "react-hot-toast";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { Base_Url } from "../constant/Url.js";

export const Products = () => {
  const [ProductsData, setProductsData] = useState([]);

  const FetchAllProducts = async () => {
    try {
      const res = await getallProducts();
      if (res?.status === "success") {
        setProductsData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    FetchAllProducts();
  }, []);

  const validationSchema = Yup.object({
    ProductPrice: Yup.number()
      .required("Product Price is required")
      .positive("Must be a positive number"),
    discountPercentage: Yup.number()
      .required("Discount Percentage is required")
      .min(0, "Cannot be less than 0")
      .max(100, "Cannot exceed 100"),
    ProductFeatures: Yup.array()
      .of(Yup.string().required("Feature is required"))
      .min(1, "At least one feature is required"),
    CategoryName: Yup.string().required("Category Name is required"),
    SubCategoryName: Yup.string().required("Sub-Category Name is required"),
    ShopName: Yup.string().required("Shop Name is required"),
    ProductName: Yup.string().required("Product Name is required"),
    ProductDescription: Yup.string().required(
      "Product Description is required"
    ),
    ProductTechnicalSpecification: Yup.string().required(
      "Technical Specification is required"
    ),
    ProductTechnicalSpecification: Yup.object({
      Power: Yup.string().required("Power is required"),
      Voltage: Yup.string().required("Voltage is required"),
      Capacity: Yup.string().required("Capacity is required"),
      Material: Yup.string().required("Material is required"),
    }).required("Technical Specifications are required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("ProductPrice", values.ProductPrice);
    formData.append("discountPercentage", values.discountPercentage);
    formData.append("CategoryName", values.CategoryName);
    formData.append("SubCategoryName", values.SubCategoryName);
    formData.append("ShopName", values.ShopName);
    formData.append("ProductName", values.ProductName);
    formData.append("ProductDescription", values.ProductDescription);

    // Append ProductTechnicalSpecification fields
    Object.entries(values.ProductTechnicalSpecification).forEach(
      ([key, value]) => {
        formData.append(`ProductTechnicalSpecification[${key}]`, value);
      }
    );

    // Attach product features
    values.ProductFeatures.forEach((feature) => {
      formData.append("ProductFeatures", feature);
    });

    // Attach images
    Array.from(values.ProductImage || []).forEach((file) => {
      formData.append("ProductImage", file);
    });

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await createProduct(formData);
      console.log(res?.data, "res?.data");
      if (res?.data?.status === "success") {
        toast.success("Product Added Successfully!");
        resetForm();
        FetchAllProducts();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [subcategories, setSubcategories] = useState([]);

  const categories = {
    "Water Purifiers": [
      "Water Purifier",
      "RO Water Purifier",
      "Water Filter",
      "UV Water Purifier",
      "Gravity Water Purifier",
      "Commercial Water Purifier",
    ],
    "Vacuum Cleaners": [
      "Wiz Handheld Vacuum Cleaner",
      "Wet & Dry Vacuum Cleaner",
    ],
    "Kitchen Appliances": ["Mixer Grinder", "Egg Boiler"],
    "Water Dispensers": [
      "Hot & Normal (Omega)",
      "Hot, Normal & Cold",
      "Hot, Normal, Cold & Cooling Cabinet",
      "Hot, Normal, Cold with RO",
    ],
  };

  const handleCategoryChange = (e, setFieldValue) => {
    const selectedCategory = e.target.value;
    setFieldValue("CategoryName", selectedCategory);
    setSubcategories(categories[selectedCategory] || []);
    setFieldValue("SubCategoryName", ""); // Reset SubCategoryName when category changes
  };

  const handleDeleteProducts = async (ele) => {
    try {
      const res = await DeleteSingleProduct(ele?._id);
      if (res?.status === "success") {
        toast.success("Product Deleted Successfully!");
        setProductsData(ProductsData.filter((item) => item._id !== ele._id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div>
        <Toaster position="top-right" reverseOrder={false} />

        <Formik
          initialValues={{
            ProductPrice: "",
            discountPercentage: "",
            ProductFeatures: [""],
            CategoryName: "",
            SubCategoryName: "",
            ShopName: "",
            ProductName: "",
            ProductDescription: "",
            ProductTechnicalSpecification: {
              Power: "",
              Voltage: "",
              Capacity: "",
              Material: "",
            },
            ProductImage: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <div>
                <label>Product Price</label>
                <Field name="ProductPrice" type="number" />
                {errors.ProductPrice && touched.ProductPrice && (
                  <div>{errors.ProductPrice}</div>
                )}
              </div>
              <div>
                <label>Discount Percentage</label>
                <Field name="discountPercentage" type="number" />
                {errors.discountPercentage && touched.discountPercentage && (
                  <div>{errors.discountPercentage}</div>
                )}
              </div>
              <div>
                <label>Category Name</label>
                <Field
                  as="select"
                  name="CategoryName"
                  onChange={(e) => handleCategoryChange(e, setFieldValue)}
                >
                  <option value="">Select a category</option>
                  {Object.keys(categories).map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </Field>
                {errors.CategoryName && touched.CategoryName && (
                  <div>{errors.CategoryName}</div>
                )}
              </div>
              <div>
                <label>Sub-Category Name</label>
                <Field as="select" name="SubCategoryName">
                  <option value="">Select a subcategory</option>
                  {subcategories.map((subcategory, index) => (
                    <option key={index} value={subcategory}>
                      {subcategory}
                    </option>
                  ))}
                </Field>
                {errors.SubCategoryName && touched.SubCategoryName && (
                  <div>{errors.SubCategoryName}</div>
                )}
              </div>

              <div>
                <label>Shop Name</label>
                <Field as="select" name="ShopName">
                  <option value="">Select a shop</option>
                  <option value="Grand Plus">Grand Plus</option>
                  <option value="Aquafresh">Aquafresh</option>
                  <option value="Keel">Keel</option>
                  <option value="Royal Aquafresh">Royal Aquafresh</option>
                </Field>
                {errors.ShopName && touched.ShopName && (
                  <div>{errors.ShopName}</div>
                )}
              </div>

              <div>
                <label>Product Name</label>
                <Field name="ProductName" />
                {errors.ProductName && touched.ProductName && (
                  <div>{errors.ProductName}</div>
                )}
              </div>
              <div>
                <label>Product Description</label>
                <Field name="ProductDescription" />
                {errors.ProductDescription && touched.ProductDescription && (
                  <div>{errors.ProductDescription}</div>
                )}
              </div>
              <div>
                <label>Technical Specifications</label>
                <div>
                  <label>Power</label>
                  <Field name="ProductTechnicalSpecification.Power" />
                  {errors.ProductTechnicalSpecification?.Power &&
                    touched.ProductTechnicalSpecification?.Power && (
                      <div>{errors.ProductTechnicalSpecification.Power}</div>
                    )}
                </div>
                <div>
                  <label>Voltage</label>
                  <Field name="ProductTechnicalSpecification.Voltage" />
                  {errors.ProductTechnicalSpecification?.Voltage &&
                    touched.ProductTechnicalSpecification?.Voltage && (
                      <div>{errors.ProductTechnicalSpecification.Voltage}</div>
                    )}
                </div>
                <div>
                  <label>Capacity</label>
                  <Field name="ProductTechnicalSpecification.Capacity" />
                  {errors.ProductTechnicalSpecification?.Capacity &&
                    touched.ProductTechnicalSpecification?.Capacity && (
                      <div>{errors.ProductTechnicalSpecification.Capacity}</div>
                    )}
                </div>
                <div>
                  <label>Material</label>
                  <Field name="ProductTechnicalSpecification.Material" />
                  {errors.ProductTechnicalSpecification?.Material &&
                    touched.ProductTechnicalSpecification?.Material && (
                      <div>{errors.ProductTechnicalSpecification.Material}</div>
                    )}
                </div>
              </div>
              <div>
                <label>Product Features</label>
                <FieldArray
                  name="ProductFeatures"
                  render={(arrayHelpers) => (
                    <div>
                      {values.ProductFeatures.map((_, index) => (
                        <div key={index}>
                          <Field name={`ProductFeatures[${index}]`} />
                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => arrayHelpers.push("")}
                      >
                        Add Feature
                      </button>
                    </div>
                  )}
                />
                {errors.ProductFeatures && touched.ProductFeatures && (
                  <div>{errors.ProductFeatures}</div>
                )}
              </div>
              <div>
                <label>Product Images</label>
                <input
                  name="ProductImage"
                  type="file"
                  multiple
                  onChange={(e) =>
                    setFieldValue("ProductImage", e.currentTarget.files)
                  }
                />
              </div>
              <button type="submit">Submit</button>
            </Form>
          )}
        </Formik>
      </div>

      <div className="row p-2 table_user_data" style={{ overflow: "auto" }}>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Category Name</th>
              <th>Sub Category Name</th>
              <th>Product Name</th>
              <th>Product Image</th>
              <th>Product Price</th>

              <th>actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(ProductsData) && ProductsData.length > 0 ? (
              ProductsData.filter((ele) => ele?.status !== 2) // Filter out rows with status = 2
                .map((ele, index) => (
                  <tr key={ele?._id}>
                    <td>{index + 1}</td>
                    <td>{ele?.CategoryName}</td>
                    <td>{ele?.SubCategoryName}</td>
                    <td>{ele?.ProductName}</td>

                    <td>
                      {/* <img
                        src={`${Base_Url}/${ele?.ProductImage}`}
                        alt=""
                        style={{ width: "50px", height: "50px" }}
                      /> */}

                      <img
                        src={`${Base_Url}${ele?.ProductImage[0]}`}
                        alt={ele?.ProductName}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>{ele?.ProductPrice}</td>

                    <td>
                      <td>
                        <div className="d-flex">
                          <div className="action_button_view">
                            <IoEye />
                          </div>
                          <div className="px-2 action_button_update">
                            <HiPencilSquare className="text-success" />
                          </div>
                          <div
                            className="action_button_delete"
                            onClick={() => handleDeleteProducts(ele)}
                          >
                            <IoTrashBinOutline className="text-danger" />
                          </div>
                        </div>
                      </td>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
