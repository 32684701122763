import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Sidebar.css";
import { Dropdown } from "react-bootstrap";
import "../../styles/UserData.css";

import { FiPackage } from "react-icons/fi";
import { LuLayoutDashboard } from "react-icons/lu";

export const Sidebar = ({ children }) => {
  const [isSidebarActive, setSidebarActive] = useState(false);

  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
  };

  const handleLogout = () => {
    // dispatch(AdminLogout()).then((res) => {
    //     navigate("/admin/login")
    // }).catch((error) => {
    //     navigate("/admin/login")
    // })
  };

  return (
    <div>
      <div
        className={`sidebar ${isSidebarActive ? "active" : ""}`}
        style={{ overflow: "auto" }}
      >
        {/* sidebar code */}
        <div className="logo-details bg-white" style={{ height: "65px" }}>
          <NavLink to="/admin" className="text-decoration-none bg-warning">
            <div className="bg-white px-3 py-2">
              <img
                src="https://www.balajiwaterpurifier.com/assets/logo/logo.png"
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </NavLink>
        </div>
        <ul className="nav-links p-0">
          <li>
            <NavLink to="/">
              <div>
                <LuLayoutDashboard />
              </div>
              <span className="links_name">Dashboard</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/category">
              <div>
                <FiPackage />
              </div>
              <span className="links_name">Category</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/subcategory">
              <div>
                <FiPackage />
              </div>
              <span className="links_name">Sub-Category</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/products">
              <div>
                <FiPackage />
              </div>
              <span className="links_name">Products</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/testimonials">
              <div>
                <FiPackage />
              </div>
              <span className="links_name">Testimonials</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/aboutus/overview">
              <div>
                <FiPackage />
              </div>
              <span className="links_name">About us Overview</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/aboutus/awards">
              <div>
                <FiPackage />
              </div>
              <span className="links_name">About us Awards</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/onlinestore">
              <div>
                <FiPackage />
              </div>
              <span className="links_name">Online Store</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/socialmedia">
              <div>
                <FiPackage />
              </div>
              <span className="links_name">Social Media</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/ourbrands">
              <div>
                <FiPackage />
              </div>
              <span className="links_name">Our Brands</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/order-management">
              <div>
                <FiPackage />
              </div>
              <span className="links_name">Order Management</span>
            </NavLink>
          </li>
        </ul>
      </div>

      {/* header */}

      <section className="home-section">
        <nav>
          <div className="sidebar-button">
            <i className="fa-solid fa-bars" onClick={toggleSidebar}></i>
            <span className="dashboard">Dashboard</span>
          </div>
          <div className="search-box">
            <input type="text" name="" placeholder="Search..." id="" />
            <i className="bx bx-search"></i>
          </div>

          <div className="profile-details">
            0 <span className="admin_name">User name</span>
            {/* 0            <span className="admin_name">{adminLoggedINData[0]?.name}</span> */}
            <Dropdown className="text-center">
              <Dropdown.Toggle className="dropdown_btn" id="dropdown-basic">
                {/* <img src={adminLoggedINData[0]?.profile} alt="" /> */}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>
                  <i class="fa-solid fa-right-from-bracket"></i>
                  &nbsp;&nbsp;&nbsp; Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </nav>
        <div className="home-content">{children}</div>
      </section>
    </div>
  );
};
