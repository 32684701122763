import React, { useEffect, useState } from "react";
import AdminuserTable from "../components/AdminuserTable";
import { GetAllTestimonials, getallUserData } from "../api/Api";
import { getallTestimonialData } from "../api/TestimonialApi";
import { getallProducts } from "../api/ProductsApi";
import { getallCategory } from "../api/CategoryApi";
import { getallSubCategory } from "../api/SubCategoryApi";
import { Link, NavLink } from "react-router-dom";
import { getAllOnlineStore } from "../api/OnlineStoreApi";
import { getallSocialMedia } from "../api/SocialMediaApi";

const AdminDashboard = () => {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [ProductData, setProductData] = useState([]);
  const [UserData, setUserData] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const [SubCategoryData, setSubCategoryData] = useState([]);
  const [OnlineStoreData, setOnlineStoreData] = useState([]);
  const [SocialMediaData, setSocialMediaData] = useState([]);

  const FetchAllTestimonials = async () => {
    try {
      const res = await getallTestimonialData();
      if (res?.status === "success") {
        setTestimonialsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllUser = async () => {
    try {
      const res = await getallUserData();
      console.log(res, "user data");
      if (res?.status === "success") {
        setUserData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllProducts = async () => {
    try {
      const res = await getallProducts();
      if (res?.status === "success") {
        setProductData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllCategory = async () => {
    try {
      const res = await getallCategory();
      if (res?.status === "success") {
        setCategoryData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllSubCategory = async () => {
    try {
      const res = await getallSubCategory();
      if (res?.status === "success") {
        setSubCategoryData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllOnlineStore = async () => {
    try {
      const res = await getAllOnlineStore();
      if (res?.status === "success") {
        setOnlineStoreData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const FetchAllSocialMedia = async () => {
    try {
      const res = await getallSocialMedia();
      if (res?.status === "success") {
        setSocialMediaData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    FetchAllTestimonials();
    FetchAllUser();
    FetchAllSubCategory();
    FetchAllCategory();
    FetchAllProducts();
    FetchAllOnlineStore();
    FetchAllSocialMedia();
  }, []);

  return (
    <>
      <div className="overview-boxes">
        <div className="box">
          <div className="right-side">
            <div style={{ fontWeight: "600", fontSize: "14px" }}>
              Total Category
            </div>
            <div className="number">{CategoryData?.length}</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>
              <NavLink to="/admin/category">
                <span className="text">Get Full Details</span>
              </NavLink>
            </div>
          </div>
          <i className="bx bx-cart-alt cart"></i>
        </div>

        <div className="box">
          <div className="right-side">
            <div style={{ fontWeight: "600", fontSize: "14px" }}>
              Total Sub Category
            </div>
            <div className="number">{SubCategoryData?.length}</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>

              <NavLink to="/admin/subcategory">
                <span className="text">Get Full Details</span>
              </NavLink>
            </div>
          </div>
          <i className="bx bx-cart-alt cart"></i>
        </div>
        <div className="box">
          <div className="right-side">
            <div style={{ fontWeight: "600", fontSize: "14px" }}>
              Total Products
            </div>
            <div className="number">{ProductData?.length}</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className="bx bx-cart-alt cart"></i>
        </div>
        <div className="box">
          <div className="right-side">
            <div style={{ fontWeight: "600", fontSize: "14px" }}>
              Total Testimonials
            </div>
            <div className="number">{testimonialsData?.length}</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className="bx bx-cart-alt cart"></i>
        </div>

        <div className="box">
          <div className="right-side">
            <div style={{ fontWeight: "600", fontSize: "14px" }}>
              Total Online Store
            </div>
            <div className="number">{OnlineStoreData?.length}</div>
            <div className="indicator">
              <NavLink to="/admin/onlinestore">
                <span className="text">Get Full Details</span>
              </NavLink>
            </div>
          </div>
          <i className="bx bx-cart-alt cart"></i>
        </div>

        <div className="box">
          <div className="right-side">
            <div style={{ fontWeight: "600", fontSize: "14px" }}>
              Total Users
            </div>
            <div className="number">{UserData?.length}</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className="bx bx-cart-alt cart"></i>
        </div>

        <div className="box">
          <div className="right-side">
            <div style={{ fontWeight: "600", fontSize: "14px" }}>
              Total Social Media
            </div>
            <div className="number">{SocialMediaData?.length}</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className="bx bx-cart-alt cart"></i>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
