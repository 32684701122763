import "./App.css";
import CommonLayoutAdmin from "./components/layout/CommonLayoutAdmin";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AdminDashboard from "./pages/AdminDashboard";
import { Category } from "./pages/Category";
import { SubCategory } from "./pages/SubCategory";
import { Products } from "./pages/Products";
import { Testimonials } from "./pages/Testimonials";
import { Aboutus } from "./pages/Aboutus";
import { AboutUsAwards } from "./pages/AboutUsAwards";
import { OnlineStore } from "./pages/OnlineStore";
import { OurBrands } from "./pages/OurBrands";
import { SocialMedia } from "./pages/SocialMedia";
import { OrderMgmt } from "./pages/OrderMgmt";
import { Login } from "./pages/Login";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route
            path="/"
            element={
              <CommonLayoutAdmin>
                <AdminDashboard />
              </CommonLayoutAdmin>
            }
          />

          <Route
            path="/category"
            element={
              <CommonLayoutAdmin>
                <Category />
              </CommonLayoutAdmin>
            }
          />
          <Route
            path="/subcategory"
            element={
              <CommonLayoutAdmin>
                <SubCategory />
              </CommonLayoutAdmin>
            }
          />
          <Route
            path="/products"
            element={
              <CommonLayoutAdmin>
                <Products />
              </CommonLayoutAdmin>
            }
          />
          <Route
            path="/products/createproduct"
            element={
              <CommonLayoutAdmin>
                <Products />
              </CommonLayoutAdmin>
            }
          />
          <Route
            path="/testimonials"
            element={
              <CommonLayoutAdmin>
                <Testimonials />
              </CommonLayoutAdmin>
            }
          />
          <Route
            path="/aboutus/overview"
            element={
              <CommonLayoutAdmin>
                <Aboutus />
              </CommonLayoutAdmin>
            }
          />
          <Route
            path="/aboutus/awards"
            element={
              <CommonLayoutAdmin>
                <AboutUsAwards />
              </CommonLayoutAdmin>
            }
          />
          <Route
            path="/onlinestore"
            element={
              <CommonLayoutAdmin>
                <OnlineStore />
              </CommonLayoutAdmin>
            }
          />
          <Route
            path="/ourbrands"
            element={
              <CommonLayoutAdmin>
                <OurBrands />
              </CommonLayoutAdmin>
            }
          />
          <Route
            path="/order-management"
            element={
              <CommonLayoutAdmin>
                <OrderMgmt />
              </CommonLayoutAdmin>
            }
          />
          <Route
            path="/socialmedia"
            element={
              <CommonLayoutAdmin>
                <SocialMedia />
              </CommonLayoutAdmin>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
