import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { GrFormAdd } from "react-icons/gr";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import {
  createSubCategory,
  deletesingleSubCategory,
  getallSubCategory,
  getSingleSubCategory,
  getSubCategoriesByCategory,
  UpdateSingleSubCategory,
} from "../api/SubCategoryApi";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import { Toaster, toast } from "react-hot-toast";
import { getallCategory } from "../api/CategoryApi";
import { Base_Url } from "../constant/Url";

const SubCategorySchema = Yup.object().shape({
  CategoryName: Yup.string().required("Category Name is required"),
  SubCategoryImage: Yup.string().required("Sub Category Image URL is required"),
  SubCategoryName: Yup.string().required("Sub Category Name is required"),
});

export const SubCategory = () => {
  const [subcategoryData, setsubcategoryData] = useState([]);
  const [subsinglecategoryData, setsubsinglecategoryData] = useState([]);

  const FetchAllCategory = async () => {
    try {
      const res = await getallSubCategory();
      if (res?.status === "success") {
        setsubcategoryData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const [showViewSubCategoryModal, setShowViewSubCategoryModal] =
    useState(false);

  const handleViewSubCategoryModalClose = () => {
    setShowViewSubCategoryModal(false);
    setsubsinglecategoryData(null); // Clear the data
  };

  const handleViewSubCategoryModalShow = async (ele) => {
    setShowViewSubCategoryModal(true);
    try {
      const res = await getSingleSubCategory(ele?._id);
      if (res?.status === "success") {
        setsubsinglecategoryData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [showCreateSubCategoryModal, setShowCreateSubCategoryModal] =
    useState(false);

  const handleCreateSubCategoryModalClose = () =>
    setShowCreateSubCategoryModal(false);
  const handleCreateSubCategoryModalShow = () =>
    setShowCreateSubCategoryModal(true);

  const [showUpdateSubCategoryModal, setShowUpdateSubCategoryModal] =
    useState(false);

  const handleUpdateSubCategoryModalClose = () => {
    setShowUpdateSubCategoryModal(false);
    setsubsinglecategoryData(null);
  };

  const handleUpdateSubCategoryModalShow = () =>
    setShowUpdateSubCategoryModal(true);

  const handleUpdateSubCategory = async (ele) => {
    handleUpdateSubCategoryModalShow();
    try {
      const res = await getSingleSubCategory(ele?._id);
      console.log(res);
      if (res?.status === "success") {
        setsubsinglecategoryData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCategory = async (ele) => {
    try {
      const res = await deletesingleSubCategory(ele?._id);
      if (res?.status === "success") {
        toast.success("Subcategory Deleted Successfully!");
        setsubcategoryData(
          subcategoryData.filter((item) => item._id !== ele._id)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedCategory, setSelectedCategory] = useState("WaterPurifiers");

  useEffect(() => {
    FetchAllCategory();
    FetchAllSubCategory(selectedCategory); // Fetch based on selected category
  }, [selectedCategory]);

  const FetchAllSubCategory = async (categoryName) => {
    try {
      const res = await getSubCategoriesByCategory(categoryName);
      console.log(res);
      if (res?.status === "success") {
        subcategoryData(res?.data);
      } else {
        console.log("No subcategories found for this category");
      }
    } catch (error) {
      console.log("Error fetching subcategory data:", error);
    }
  };

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
    FetchAllSubCategory(categoryName);
  };

  const [categoryListData, setcategoryListData] = useState([]); // Renamed for clarity

  const FetchAllCategoryList = async () => {
    try {
      const res = await getallCategory();
      if (res?.status === "success") {
        setcategoryListData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    FetchAllCategoryList();
  }, []);

  const handleSubmitUpdate = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("CategoryName", values.CategoryName);
    formData.append("SubCategoryName", values.SubCategoryName);
    if (values.SubCategoryImage) {
      formData.append("SubCategoryImage", values.SubCategoryImage);
    } else {
      formData.append(
        "SubCategoryImage",
        subsinglecategoryData.SubCategoryImage
      );
    }

    try {
      const res = await UpdateSingleSubCategory(
        subsinglecategoryData._id,
        formData
      );
      if (res?.data?.status === "success") {
        setsubcategoryData((prevData) =>
          prevData.map((item) =>
            item._id === subsinglecategoryData._id
              ? {
                  ...item,
                  CategoryName: values.CategoryName,
                  SubCategoryName: values.SubCategoryName,
                  SubCategoryImage:
                    res.data?.data?.SubCategoryImage || item.SubCategoryImage,
                }
              : item
          )
        );
        toast.success("Our Subcategory Updated Successfully!");
        handleUpdateSubCategoryModalClose();
      }
    } catch (error) {
      console.error("Error updating Our Brands:", error);
      toast.error("Oops! Some error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitCreateSubCategory = async (values, { setSubmitting }) => {
    const formData = new FormData();

    formData.append("CategoryName", values.CategoryName);
    formData.append("SubCategoryImage", values.SubCategoryImage);
    formData.append("SubCategoryName", values.SubCategoryName);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await createSubCategory(formData);
      if (res?.data?.status === "success") {
        setsubcategoryData([...subcategoryData, res.data.subcategory]);
        toast.success("Sub Category Added Successfully!");
        handleCreateSubCategoryModalClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error creating testimonial:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="container">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="row">
          <div className="d-flex justify-content-between">
            <div>
              <span style={{ fontWeight: "600", fontSize: "20px" }}>
                Sub-Category Management
              </span>
            </div>

            <div>
              <button
                className="addbutton"
                onClick={handleCreateSubCategoryModalShow}
              >
                Add Sub Category
                <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
              </button>
            </div>
          </div>
        </div>

        {/* Table - All Subcategory */}

        <div className="row py-4 table_user_data" style={{ overflow: "auto" }}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Category Name</th>
                <th>Sub Category Name</th>
                <th>Category Image</th>
                <th>actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(subcategoryData) && subcategoryData.length > 0 ? (
                subcategoryData?.map((ele, index) => (
                  <tr key={ele?._id}>
                    <td>{index + 1}</td>
                    <td>{ele?.CategoryName}</td>
                    <td>{ele?.SubCategoryName}</td>
                    <td>
                      <img
                        src={`http://localhost:9090${ele?.SubCategoryImage}`}
                        alt=""
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>
                      <td>
                        <div className="d-flex">
                          <div
                            onClick={() => handleViewSubCategoryModalShow(ele)}
                            className="action_button_view"
                          >
                            <IoEye />
                          </div>
                          <div
                            className="px-2 action_button_update"
                            onClick={() => handleUpdateSubCategory(ele)}
                          >
                            <HiPencilSquare className="text-success" />
                          </div>
                          <div
                            className="action_button_delete"
                            onClick={() => handleDeleteCategory(ele)}
                          >
                            <IoTrashBinOutline className="text-danger" />
                          </div>
                        </div>
                      </td>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {/* View  Subcategory */}

        <div className="row">
          <Modal
            show={showViewSubCategoryModal}
            onHide={handleViewSubCategoryModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Single Sub category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="modaldata d-flex pb-3">
                  <span>CategoryName</span>
                  {subsinglecategoryData?.CategoryName}
                </div>
                <div className="modaldata d-flex pb-3">
                  <span>SubCategoryName : </span>
                  {subsinglecategoryData?.SubCategoryName}
                </div>
                <div className="modaldata d-flex pb-3">
                  <span>Category Image : </span>
                  <div>
                    <img
                      src={`${Base_Url}${subsinglecategoryData?.SubCategoryImage}`}
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {/* Create Subcategory */}

        <div className="row">
          <Modal
            show={showCreateSubCategoryModal}
            onHide={handleCreateSubCategoryModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Create Sub Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  CategoryName: "",
                  SubCategoryImage: null,
                  SubCategoryName: "",
                }}
                validationSchema={SubCategorySchema}
                onSubmit={handleSubmitCreateSubCategory}
              >
                {({ setFieldValue, values, isSubmitting }) => (
                  <Form>
                    <div>
                      <div>Category Name</div>
                      <Field
                        as="select"
                        name="CategoryName"
                        className="Form_Field"
                      >
                        <option value="">Select Category Name</option>
                        {categoryListData.map((category) => (
                          <option
                            key={category._id}
                            value={category.CategoryName}
                          >
                            {category.CategoryName}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="CategoryName"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div>Sub Category Name</div>
                      <Field
                        type="text"
                        name="SubCategoryName"
                        className="Form_Field"
                      />
                      <ErrorMessage
                        name="SubCategoryName"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div>Sub Category Image</div>
                      <input
                        type="file"
                        name="SubCategoryImage"
                        className="Form_Field"
                        onChange={(event) => {
                          setFieldValue(
                            "SubCategoryImage",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <ErrorMessage
                        name="SubCategoryImage"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCreateSubCategoryModalClose}
                        disabled={isSubmitting}
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={isSubmitting}
                      >
                        Create
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </div>

        {/* Update  Subcategory */}

        <div className="row">
          <Modal
            show={showUpdateSubCategoryModal}
            onHide={handleUpdateSubCategoryModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Sub category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {subsinglecategoryData && (
                <Formik
                  initialValues={{
                    CategoryName: subsinglecategoryData?.CategoryName || "",
                    SubCategoryImage:
                      subsinglecategoryData?.SubCategoryImage || null,
                    SubCategoryName:
                      subsinglecategoryData?.SubCategoryName || "",
                  }}
                  validationSchema={SubCategorySchema}
                  onSubmit={handleSubmitUpdate}
                >
                  {({ setFieldValue, isSubmitting }) => (
                    <Form>
                      <div>
                        <div>Category Name</div>
                        <Field
                          type="text"
                          name="CategoryName"
                          className="Form_Field"
                        />
                        <ErrorMessage
                          name="CategoryName"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div>
                        <div>SubCategory Name</div>
                        <Field
                          type="text"
                          name="SubCategoryName"
                          className="Form_Field"
                        />
                        <ErrorMessage
                          name="SubCategoryName"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="mt-2">
                        <div>SubCategoryImage</div>
                        <input
                          type="file"
                          name="SubCategoryImage"
                          className="Form_Field"
                          onChange={(event) => {
                            setFieldValue(
                              "SubCategoryImage",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        <ErrorMessage
                          name="OurBrandsImage"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleUpdateSubCategoryModalClose}
                          disabled={isSubmitting}
                        >
                          Close
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={isSubmitting}
                        >
                          Update
                        </Button>
                      </Modal.Footer>
                    </Form>
                  )}
                </Formik>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};
